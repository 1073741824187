export default {
  yes: 'Sim',
  no: 'Não',
  map_options: "Opções do Mapa",
  reset_map_filters: "Redefinir filtros do mapa e localização",
  disclaimers: "Avisos",
  show_disclaimers: "Mostrar avisos",
  search_locations: "Buscar locais",
  no_options: "Sem opções",
  show_admin1_boundaries: "Mostrar limites Admin1",
  show_admin1_names: "Mostrar nomes Admin1",
  show_all_iu_boundaries: "Mostrar todos os limites de II",
  layers_by_year: "Camadas por Ano",
  tf_prevalence: "Prevalência de TF",
  year: "Ano",
  previous_year: "Anterior",
  next_year: "Próximo",
  treatment: "Tratamento",
  summary_layers: "Camadas Resumo",
  baseline_tf_prevalence: "Prevalência de TF de Referência",
  cumulative_rounds: "Rodadas Cumulativas de Tratamento",
  fifty_reduction_tf_prevalence: "Redução de 50% na Prevalência de TF",
  country_elimination_status: "Status de Eliminação do País",
  tt_prevalence: "Prevalência de TT",
  legend: "Legenda",
  user_guide: "Guia do Usuário",
  download_as: "Baixar como",
  image: "Imagem",
  pdf: "PDF",
  zoom_in: "Aproximar",
  zoom_out: "Afastar",
  reset_bearing_to_north: "Redefinir orientação para o norte",
  note: "Nota: o logotipo do Mapbox e a atribuição de texto são obrigatórios e não podem ser modificados para mapas do Trachoma Atlas usados em publicações",
  all_disclaimer: "Estes mapas foram projetados pela Iniciativa Internacional de Tracoma (ITI) e desenvolvidos pela Bastion Data. Os dados apresentados foram relatados pelos gerentes de programas ao banco de dados compartilhado pela ITI e OMS GET2020. Os limites, nomes e designações no mapa não refletem qualquer posicionamento dos desenvolvedores ou das instituições afiliadas sobre o status legal de países, territórios, cidades ou autoridades, nem sobre a delimitação de fronteiras.",
  tt_prevalence_maps_disclaimer: "Os mapas de prevalência de Imflamação Tracomatosa-Folicular (TF) apresentam a prevalência categórica mais recente de TF em crianças de 1 a 9 anos por distrito para o ano selecionado.",
  baseline_tf_prevalence_disclaimer: "Os mapas de prevalência de TF de referência apresentam a prevalência categórica de referência de TF em crianças de 1 a 9 anos por distrito.",
  tt_prevalence_maps_adults_disclaimer: "Os mapas de prevalência de Triquíase Tracomatosa (TT) apresentam a prevalência categórica mais recente de TT em adultos com 15 anos ou mais por distrito.",
  treatment_maps_disclaimer: "Os mapas de tratamento indicam se um distrito recebeu tratamento no ano selecionado. Os dados de tratamento de um determinado ano não são relatados até o segundo trimestre do ano seguinte.",
  cumulative_rounds_maps_disclaimer: "O mapa de rodadas cumulativas apresenta o número cumulativo de rodadas de tratamento distribuídas por distrito.",
  fifty_reduction_tf_prevalence_disclaimer: "O mapa de redução de 50% apresenta distritos com redução ≥ 50% na prevalência de TF em crianças de 1 a 9 anos, desde a pesquisa de referência até a pesquisa mais recente.",
  elimination_status_map_disclaimer: "O mapa de status de eliminação do país apresenta o status da eliminação do tracoma como um problema de saúde pública por país. As informações são baseadas no status mais recente relatado no Observatório Global de Saúde (http://apps.who.int/gho/data/node.main.A1645T?lang=en).",
  elimination_status_map_disclaimer_html: "O mapa de status de eliminação do país apresenta o status da eliminação do tracoma como um problema de saúde pública por país. As informações são baseadas no status mais recente relatado no Observatório Global de Saúde (http://apps.who.int/gho/data/node.main.A1645T?lang=en).",
  legend_tf_prevalence: "Prevalência de TF",
  legend_lt5: "<5%",
  legend_5_99: "5-9,9%",
  legend_10_299: "10-29,9%",
  legend_30_499: "30-49,9%",
  legend_gte50: "≥50%",
  legend_1: "1",
  legend_2: "2",
  legend_3: "3",
  legend_4: "4",
  legend_gte5: "≥5",
  legend_lt_02: "<0,2%",
  legend_gte_02: "≥0,2%",
  legend_suspected_endemic: "Suspeita de endemicidade",
  legend_treatement: "Tratamento",
  legend_received_treatment: "Recebeu tratamento",
  legend_baseline_tf_prevalence: "Prevalência de TF de Referência",
  legend_cumulative_rounds_of_treatment: "Rodadas Cumulativas de Tratamento",
  legend_50_reduction_tf_prevalence: "Redução de 50% na Prevalência de TF",
  legend_gte_50_reduction_tf_prevalence: "≥ 50% de redução na prevalência de TF",
  legend_country_elimination_status: "Status de Eliminação do País",
  legend_not_require_intervention_claims_eliminated: "Acredita-se que não requer intervenções; reivindica ter eliminado",
  legend_not_require_intervention: "Acredita-se que não requer intervenções",
  legend_validated_eliminated: "Validado como eliminado",
  legend_known_require_intervention: "Conhecido por necessitar intervenções",
  legend_may_require_intervention: "Pode necessitar intervenções; investigação necessária",
  legend_tt_prevalence: "Prevalência de TT",
  legend_ius: "IIs",
  popup_elimination_status: "Status de eliminação",
  popup_tf_prevalence: "Prevalência de TF",
  popup_tf_category: "Categoria de TF",
  popup_year_of_survey: "Ano da Pesquisa",
  popup_type_of_survey: "Tipo de Pesquisa",
  popup_treatment: "Tratamento",
  popup_received_treatment: "Recebeu tratamento",
  popup_baseline_tf_prevalence: "Prevalência de TF de Referência",
  popup_cumulative_rounds: "Rodadas Cumulativas de Tratamento",
  popup_rounds: "Rodadas de Tratamento",
  popup_tt_prevalence: "Prevalência de TT",
  popup_current_tt_category: "Categoria Atual de TT",
  popup_tt_survey_year: "Ano da Pesquisa de TT",
  popup_tt_survey_type: "Tipo de Pesquisa de TT",
  popup_not_reported_current_year: "Os dados de tratamento de um determinado ano não são relatados até o segundo trimestre do ano seguinte.",
  "Suspected Endemic": "Suspeita de endemicidade",
  "5-9.9%": "5-9,9%",
  "10-29.9%": "10-29,9%",
  "30-49.9%": "30-49,9%",
  "<5%": "<5%",
  ">=50%": "≥50%",
  "OR": "OU",
  "Impact": "Impacto",
  "Surveillance": "Vigilância",
  "Baseline": "Referência",
  "TT1": "TT1",
  ">=0.2%": "≥0,2%",
  "<0.2%": "<0,2%"
}
