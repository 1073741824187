import en from './en'
import fr from './fr'
import es from './es'
import pt from './pt'

export default class Locale {

  constructor() {
    const language = navigator.language;
    
    if (language.startsWith('fr')) {
      this.locale = 'fr';
    } else if (language.startsWith('es')) {
      this.locale = 'es';
    } else if (language.startsWith('pt')) {
      this.locale = 'pt';
    } else {
      this.locale = 'en';
    }
  }

  t(key, failover) {
    var text = failover ? key : 'TRANSLATION MISSING';
    if (this.locale === 'fr') {
      if (fr[key]) text = fr[key];
    } else if (this.locale === 'es') {
      if (es[key]) text = es[key];
    } else if (this.locale === 'pt') {
      if (pt[key]) text = pt[key];
    } else {
      if (en[key]) text = en[key];
    }
    return text;
  }
}