export default {
  yes: 'Sí',
  no: 'No',
  map_options: "Opciones del Mapa",
  reset_map_filters: "Restablecer filtros del mapa y ubicación",
  disclaimers: "Avisos",
  show_disclaimers: "Mostrar avisos",
  search_locations: "Buscar ubicaciones",
  no_options: "Sin opciones",
  show_admin1_boundaries: "Mostrar límites de Admin1",
  show_admin1_names: "Mostrar nombres de Admin1",
  show_all_iu_boundaries: "Mostrar todos los límites de IU",
  layers_by_year: "Capas por Año",
  tf_prevalence: "Prevalencia de TF",
  year: "Año",
  previous_year: "Anterior",
  next_year: "Siguiente",
  treatment: "Tratamiento",
  summary_layers: "Capas Resumen",
  baseline_tf_prevalence: "Prevalencia de TF de Referencia",
  cumulative_rounds: "Rondas Acumulativas de Tratamiento",
  fifty_reduction_tf_prevalence: "Reducción del 50% en la Prevalencia de TF",
  country_elimination_status: "Estado de Eliminación del País",
  tt_prevalence: "Prevalencia de TT",
  legend: "Leyenda",
  user_guide: "Guía del Usuario",
  download_as: "Descargar como",
  image: "Imagen",
  pdf: "PDF",
  zoom_in: "Acercar",
  zoom_out: "Alejar",
  reset_bearing_to_north: "Restablecer orientación al norte",
  note: "Nota: el logotipo de Mapbox y la atribución de texto son obligatorios y no pueden modificarse en los mapas del Trachoma Atlas utilizados en publicaciones",
  all_disclaimer: "Estos mapas fueron diseñados por la Iniciativa Internacional del Tracoma (ITI) y desarrollados por Bastion Data. Los datos presentados fueron reportados por los gerentes de programas a la base de datos compartida por la ITI y la OMS GET2020. Los límites, nombres y designaciones en el mapa no reflejan ninguna posición por parte de los desarrolladores o de las instituciones afiliadas sobre el estatus legal de países, territorios, ciudades o autoridades, ni sobre la delimitación de fronteras.",
  tt_prevalence_maps_disclaimer: "Los mapas de prevalencia de TF presentan la prevalencia categórica más reciente de TF en niños de 1 a 9 años por distrito para el año seleccionado.",
  baseline_tf_prevalence_disclaimer: "Los mapas de prevalencia de TF de referencia presentan la prevalencia categórica de referencia de TF en niños de 1 a 9 años por distrito.",
  tt_prevalence_maps_adults_disclaimer: "Los mapas de prevalencia de TT presentan la prevalencia categórica más reciente de TT en adultos de 15 años o más por distrito.",
  treatment_maps_disclaimer: "Los mapas de tratamiento indican si un distrito recibió tratamiento en el año seleccionado. Los datos de tratamiento de un año determinado no se informan hasta el segundo trimestre del año siguiente.",
  cumulative_rounds_maps_disclaimer: "El mapa de rondas acumulativas presenta el número acumulado de rondas de tratamiento distribuidas por distrito.",
  fifty_reduction_tf_prevalence_disclaimer: "El mapa de reducción del 50% presenta distritos con una reducción ≥ 50% en la prevalencia de TF en niños de 1 a 9 años desde la encuesta de referencia hasta la encuesta más reciente.",
  elimination_status_map_disclaimer: "El mapa de estado de eliminación del país presenta el estado de eliminación del tracoma como un problema de salud pública por país. La información se basa en el estado más reciente informado en el Observatorio Mundial de la Salud (http://apps.who.int/gho/data/node.main.A1645T?lang=en).",
  elimination_status_map_disclaimer_html: "El mapa de estado de eliminación del país presenta el estado de eliminación del tracoma como un problema de salud pública por país. La información se basa en el estado más reciente informado en el Observatorio Mundial de la Salud (http://apps.who.int/gho/data/node.main.A1645T?lang=en).",
  legend_tf_prevalence: "Prevalencia de TF",
  legend_lt5: "<5%",
  legend_5_99: "5-9,9%",
  legend_10_299: "10-29,9%",
  legend_30_499: "30-49,9%",
  legend_gte50: "≥50%",
  legend_1: "1",
  legend_2: "2",
  legend_3: "3",
  legend_4: "4",
  legend_gte5: "≥5",
  legend_lt_02: "<0,2%",
  legend_gte_02: "≥0,2%",
  legend_suspected_endemic: "Sospecha de endemicidad",
  legend_treatement: "Tratamiento",
  legend_received_treatment: "Recibió tratamiento",
  legend_baseline_tf_prevalence: "Prevalencia de TF de Referencia",
  legend_cumulative_rounds_of_treatment: "Rondas Acumulativas de Tratamiento",
  legend_50_reduction_tf_prevalence: "Reducción del 50% en la Prevalencia de TF",
  legend_gte_50_reduction_tf_prevalence: "≥ 50% de reducción en la prevalencia de TF",
  legend_country_elimination_status: "Estado de Eliminación del País",
  legend_not_require_intervention_claims_eliminated: "Se cree que no requiere intervenciones; afirma haber eliminado",
  legend_not_require_intervention: "Se cree que no requiere intervenciones",
  legend_validated_eliminated: "Validado como eliminado",
  legend_known_require_intervention: "Se sabe que requiere intervenciones",
  legend_may_require_intervention: "Puede requerir intervenciones; se necesita investigación",
  legend_tt_prevalence: "Prevalencia de TT",
  legend_ius: "IUs",
  popup_elimination_status: "Estado de eliminación",
  popup_tf_prevalence: "Prevalencia de TF",
  popup_tf_category: "Categoría de TF",
  popup_year_of_survey: "Año de la Encuesta",
  popup_type_of_survey: "Tipo de Encuesta",
  popup_treatment: "Tratamiento",
  popup_received_treatment: "Recibió tratamiento",
  popup_baseline_tf_prevalence: "Prevalencia de TF de Referencia",
  popup_cumulative_rounds: "Rondas Acumulativas de Tratamiento",
  popup_rounds: "Rondas de Tratamiento",
  popup_tt_prevalence: "Prevalencia de TT",
  popup_current_tt_category: "Categoría Actual de TT",
  popup_tt_survey_year: "Año de la Encuesta de TT",
  popup_tt_survey_type: "Tipo de Encuesta de TT",
  popup_not_reported_current_year: "Los datos de tratamiento de un año determinado no se informan hasta el segundo trimestre del año siguiente.",
  "Suspected Endemic": "Sospecha de endemicidad",
  "5-9.9%": "5-9,9%",
  "10-29.9%": "10-29,9%",
  "30-49.9%": "30-49,9%",
  "<5%": "<5%",
  ">=50%": "≥50%",
  "OR": "O",
  "Impact": "Impacto",
  "Surveillance": "Vigilancia",
  "Baseline": "Referencia",
  "TT1": "TT1",
  ">=0.2%": "≥0,2%",
  "<0.2%": "<0,2%"
}
